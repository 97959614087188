<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else-if="!isQueueing" id="body-content-area">
        <div id="buttongroup" style="display: flex; justify-content: flex-end;">
          <div class="field is-grouped">
            <div class="control">
              <button type="button" @click="generate" class="button is-accent has-text-white" :disabled="selectedInstitutions.length === 0 || !input.year || input.month === ''">
                <i class="fa fa-plus" style="margin-right: 1em;"></i>
                Generate Reports

                <i v-if="isSaving" style="margin-left: .75rem;" class="fa fa-spinner fa-spin"></i>
              </button>
            </div>
            <div class="control">
              <a class="button is-light" v-on:click="$router.go(-1)">Cancel</a>
            </div>
          </div>
        </div>
        <form @submit.prevent="updateReport()">
          <div id="pagelayout">
            <div class="columns">
              <div class="column" style="display: flex; flex-wrap: wrap;">
                <!-- REPORT PROPERTIES -->
                <div
                  class="block"
                  style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray; width: 100%;"
                >
                  <span class="has-text-weight-bold">Report Batch Details</span>
                </div>
                <div class="block" style="padding: .5rem;">
                  <div style="width: 100%;">
                    <div class="field"  style="width: 100%;">
                      <label class="label is-size-7">
                        Year
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control select"  style="width: 100%;">
                        <select v-model="input.year"  style="width: 100%;">
                          <option
                            v-for="opt in years"
                            :key="opt"
                            :value="opt"
                          >{{opt}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="field" style="width: 100%;">
                      <label class="label is-size-7">
                        Month
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control select" style="width: 100%;">
                        <select v-model="input.month"  style="width: 100%;">
                          <option
                            v-for="opt in months"
                            :key="opt"
                            :value="opt"
                          >{{opt}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block" style="padding: .5rem;">
                  <div id="institution-info-section-data" class="block">
                    <div class="columns is-vcentered" style="align-items: flex-start !important;">
                      <div class="column">
                        <div class="field">
                          <label class="label is-size-7">
                            Institution
                            <span class="has-text-warning" style="padding-left: 3px;">*</span>
                          </label>
                          <div class="control">
                            <SearchableMultiSelect
                                style="width: 100%; min-width: 350px;"
                                v-if="institutions.length > 0"
                                @onchange="onInstitutionsChange"
                                :identifier="'SearchableMultiSelect'"
                                :options="institutions"
                                :selectedOptions="selectedInstitutions"
                                :stayOpen="true"
                              />
                            <div v-else>
                              <small class="has-text-gray pill">
                                All institutions have reports generated for this period.
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column"  style="min-height: 100% !important;">
                        <div class="field"  style="min-height: 100% !important;">
                          <label class="label is-size-7">
                            Selected Institutions
                          </label>
                          <div class="control" style="min-height: 100% !important;">
                            <SearchableMultiSelect
                                style="width: 100%; min-width: 350px; height: 100%;"
                                v-if="selectedInstitutions.length > 0"
                                @onchange="onRemoveInstitution"
                                :identifier="'SearchableMultiSelect'"
                                :options="selectedInstitutions"
                                :stayOpen="true"
                                :disabled="true"
                                :is-selected="true"
                            >
                            </SearchableMultiSelect>
                            <div v-else>
                              <small class="has-text-gray pill">
                                No Institutions have been selected.
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
      <div v-else>
        <center>
          <h1 class="title is-4">We're queuing the reports. This may take a moment.</h1>
          <Loading />
        </center>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../Loading'
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import moment from 'moment'
import SearchableMultiSelect from '../partials/SearchableMultiSelect'

let pageName = 'Asset Reporting - Generate Reports'
let currentYear = moment().year()
let currentMonth = moment().format('MMMM')

export default {
  components: {
    Loading,
    SearchableMultiSelect
  },
  data () {
    return {
      input: {
        year: currentYear,
        month: currentMonth
      },
      isLoading: false,
      institutions: new Map(),
      institutionsMap: new Map(),
      selectedInstitutions: [],
      institutionsArr: [],
      institutionsObject: {},
      pageheading: pageName,
      errors: [],
      isSaving: false,
      isQueueing: false
    }
  },
  watch: {
    'input.month': async function () {
      this.resetInstitutions()
      this.getInstitutionsForReportingPeriod(false)
    },
    'input.year': async function () {
      this.isLoading = true
      this.resetInstitutions()
      this.getInstitutionsForReportingPeriod(false)
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    reportingPeriod () {
      return moment().month(this.input.month).year(this.input.year).format('YYYY-MM-01 00:00:00.000')
    },
    years () {
      let years = []
      for (let i = 0; i <= 11; i++) {
        years.push(currentYear + 1 - i)
      }
      return years
    },
    months () {
      let months = []

      for (let i = 0; i < 12; i++) {
        months.push(moment().month(i).format('MMMM'))
      }

      return months
    },
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    }
  },
  async created () {
    await this.getInstitutionsForReportingPeriod()
  },
  methods: {
    resetInstitutions () {
      this.selectedInstitutions = []
      // this.institutions = this.institutionsMap
    },
    onInstitutionsChange (selected) {
      this.selectedInstitutions = selected
      this.institutions = this.institutions.filter(record => {
        return !selected.some(selectedRecord => selectedRecord.id === record.id)
      })
    },
    onRemoveInstitution (selected) {
      if (typeof selected === 'undefined') {
        return
      } else if (Array.isArray(selected)) {
        this.institutions = this.institutionsMap

        if (selected.length > 0) {
          selected.forEach(inst => {
            this.institutions = this.institutions.filter(record => record.id !== inst.id)
          })
        }
      } else {
        this.institutions = this.institutionsMap.filter(record => record.id !== selected.id)
      }

      this.selectedInstitutions = selected
    },
    generate () {
      try {
        this.isQueueing = true

        this.api().assetReports.queueReportsToGenerate({
          institutions: this.selectedInstitutions.map(record => `${record.id}:${record.subsidiaryId}`),
          period: this.reportingPeriod
        }, (error, result) => {
          if (error) {
            this.isQueueing = false
            this.handleApiErr(error)
            return
          }

          this.isQueueing = false
          this.$router.push({ name: 'AssetReporting' })
        })
      } catch (error) {
        this.handleApiErr(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getInstitutionsForReportingPeriod (setIsLoading = true) {
      try {
        this.isLoading = setIsLoading
        this.api().assetReports.getInstitutionsForReportingPeriod({
          reportingPeriod: this.reportingPeriod
        }, (_error, result) => {
          if (!result || result.length === 0) {
            // this.handleApiErr('No institutions found for the selected reporting period.')
            this.institutions = []
          } else {
            this.institutions = result.filter(record => {
              record.id = `${record.id}${record.subsidiaryId && record.subsidiaryId !== '' ? `:${record.subsidiaryId}` : ''}`
              record.label = `${record.id} - ${record.name}`
              return record
            })

            this.institutionsMap = [...this.institutions]
          }

          window.requestAnimationFrame(() => {
            this.isLoading = false
            this.$forceUpdate()
          })
        })
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
  input[type="checkbox"]:disabled,
  input[type="checkbox"]:disabled+label{
    cursor:no-drop;
  }
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
#pagelayout {
  right: 100%;
  bottom: 0;
}
.filepond--list-scroller {
  transform: translate3d(0px, 0px, 0) !Important;
}
.filepond--drop-label {
  font-size: 1.5rem;
  color: #7fb942;
}

.pill {
  border-radius: 4px;
  padding: 5px 10px;
  background-color: #f5f5f5;
  color: #7fb942;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 5px;
  display: inline-block;
  border: 1px solid #dfdfdf;

  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
